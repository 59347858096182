<template>
    <div v-if="myCrewedVessels.length > 0" class="my-fleets">
        <ListMyVessels v-for="vessel in myCrewedVessels" :sentVessel="vessel" :inverted="false" :key="vessel.vesselUVI" v-on:clicked="otherAction">
            <template #heading>{{ vessel.vesselName }}</template>
        </ListMyVessels>
    </div>

    <div v-else class="my-fleets">
        <div class="centered">
            <div class="spacer s20"></div>
            <h4>You have no assets yet.</h4>
            <div class="spacer s10"></div>
        </div>
    </div>
</template>

<script>
import ListMyVessels from '@/components/dashboard/ListMyVessels.vue';

import { getFirestore, collection, query, where, onSnapshot } from 'firebase/firestore';
import { loggedInUserStore } from '@/stores/userdata';

export default {
    setup() {
        const user_store = loggedInUserStore();
        // const db = getFirestore()
        return {
            user_store: user_store
            // db: db
        };
    },

    mounted() {
        setTimeout(() => {
            this.loadMyFleets();
        }, 1500);
    },

    components: {
        ListMyVessels
    },

    computed: {
        userData() {
            return this.user_store.getUserData;
        },
        userID() {
            return this.user_store.getUserID;
        },
        userIsLoggedIn() {
            return this.user_store.getUserIsLoggedIn;
        },
        myCrewedVessels() {
            return this.user_store.getMyCrewedVessels;
        }
    },

    methods: {
        async loadMyFleets() {
            let self = this;
            const q = query(collection(getFirestore(), 'userdata/' + this.userID + '/myCompanies'));
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                this.myFleets = [];
                querySnapshot.forEach((doc) => {
                    self.myFleets.push(doc.data());
                });
            });
            this.loadingFleets = false;
            let test = 0;
        },
        otherAction(result) {
            // let result = {
            //     asset: this.sentVessel
            // };
            this.$emit('clicked', result);
        }
    },
    data() {
        return {
            myFleets: [],
            loadingFleets: true,
            fleetOne: {
                name: 'OffshoreSMS Fleet',
                path: '/fleet-home-page'
            },
            fleetTwo: {
                name: 'Ambush Workboats',
                path: '/fleet-home-page'
            },
            varX: 123
        };
    }
};
</script>
