<template>
    <MobileNav />

    <div class="page-wrapper centered">
        <div class="spacer s10"></div>
        <div v-if="userIsLoggedIn" class="container">
            <div class="header-image" :style="{ backgroundImage: `url(${backgrounds[dashBackground]})` }">
                <div class="profile-avatar">
                    <span
                        v-if="userData.avatarUrl && userData.avatarUrl != ''"
                        :style="{ backgroundImage: `url(${userData.avatarUrl})` }"
                        class="avatar"></span>
                    <span v-else class="avatar round-button-letters">{{ getAvatar(userData.username) }}</span>
                </div>
                <div class="edit-button" @click="showBackgrounds = true"><font-awesome-icon icon="pen" /></div>
                <div class="home-title">My Home Page</div>
            </div>
            <div class="buttons header-buttons desktop">
                <!-- <router-link class="button is-success" to="testing-dropzone"> <font-awesome-icon icon="camera" /></router-link> -->
                <!-- <span class="button is-warning" @click="">
                    <font-awesome-icon icon="globe" /> &nbsp;Support
                </span> -->
                <!-- <span class="button is-dark" @click="">
                    <font-awesome-icon icon="comment" /> &nbsp;Messages
                    <span v-if="alerts.messages > 0" class="counter">{{ alerts.messages }}</span>
                </span> -->
                <!-- <span class="button is-info" @click="">
                    <font-awesome-icon icon="globe" /> &nbsp;News
                    <span v-if="alerts.news > 0" class="counter">{{ alerts.news }}</span>
                </span> -->
            </div>
            <div class="buttons header-buttons mobile">
                <!-- <span class="button is-warning is-small" @click="">
                    <font-awesome-icon icon="globe" /> &nbsp;Support
                </span>
                <span class="button is-dark is-small" @click="">
                    <font-awesome-icon icon="comment" /> &nbsp;Messages
                    <span v-if="alerts.messages > 0" class="counter">{{ alerts.messages }}</span>
                </span>
                <span class="button is-info is-small" @click="">
                    <font-awesome-icon icon="globe" /> &nbsp;News
                    <span v-if="alerts.news > 0" class="counter">{{ alerts.news }}</span>
                </span> -->
            </div>
        </div>
        <div v-else class="container">
            <div class="spacer s30"></div>
            <div class="splash-header-image">
                <div class="hero-content">
                    <h1>Tools for Commercial Marine Businesses</h1>
                    <p>
                        Easy to use, completely paperless and free to get started. OffshoreSMS helps you manage safety and compliance on your
                        commercial vessels.
                    </p>
                    <div class="buttons">
                        <button class="button is-primary is-medium" @click="authenticate('login')">
                            <font-awesome-icon icon="user" /> &nbsp;Login as a user
                        </button>
                        <button class="button is-info is-medium" @click="authenticate('register')">
                            <font-awesome-icon icon="pen" /> &nbsp;Register a new account
                        </button>
                    </div>
                </div>
            </div>
            <div class="spacer s20"></div>
            <!-- <div class="notification">
                <p>Please sign in to view this content</p>
                <div class="spacer s20"></div>
                <div class="buttons centered">
                    <RouterLink class="button is-success" to="/authenticate">Sign In</RouterLink>
                </div>
            </div> -->
        </div>
    </div>

    <div v-if="userIsLoggedIn" class="container">
        <div class="home-page-wrapper">
            <div class="">
                <vue-element-loading :active="isLoading" spinner="bar-fade-scale" />

                <!-- <div>::{{ crewedVesselsLoaded }}</div> -->

                <HomePageTabs />

                <!-- <div class="toggle-wrapper">
                    <div class="toggle-item crew" @click="currentTab = 'crew'" :class="{ active: currentTab == 'crew' }">
                        <div class="toggle-title">Logs Portal</div>
                        <div class="spacer s10"></div>
                        <div class="buttons">
                            <span class="button is-primary is-medium" @click="showCrewPortal = true">View</span>
                        </div>
                    </div>
                    <div class="toggle-item fleet" @click="currentTab = 'fleet'" :class="{ active: currentTab == 'fleet' }">
                        <div class="toggle-title">Marine Assets</div>
                        <div class="spacer s10"></div>
                        <div class="buttons">
                            <span class="button is-primary is-medium" @click="showFleetPortal = true">View</span>
                        </div>
                    </div>
                </div>

                <div class="tabs is-toggle is-fullwidth">
                    <ul>
                        <li>
                            <a class="tab-button" :class="{ selected: tab == '1' }" @click="tab = '1'">Vessels I Work On</a>
                        </li>
                        <li>
                            <a class="tab-button" :class="{ selected: tab == '2' }" @click="tab = '2'">Fleets I Manage</a>
                        </li>
                    </ul>
                </div>

                <div v-if="tab == '1'" class="db-fleet-list">
                    <div class=""><DashboardMyVessels /></div>
                    <hr />
                    <div class="centered">
                        <a class="button is-success" @click="hasSeenFleetPrompt ? (showAddVessel = true) : (showFleetPrompt = true)"
                            >Add a New Vessel</a
                        >
                    </div>
                </div>

                <div v-if="tab == '2'" class="db-fleet-list">
                    <div class=""><DashboardAllFleets /></div>
                    <hr />
                    <div class="centered">
                        <a class="button is-info" @click="showAddFleet = true">Add a New Fleet</a>
                    </div>
                    <div class="spacer s30"></div>
                </div> -->
            </div>
        </div>
    </div>

    <div class="container">
        <div class="home-footer">
            <div class="spacer s30"></div>
            <hr />
            <div class="buttons centered">
                <router-link class="button is-light" to="/support"> <font-awesome-icon icon="life-ring" /> &nbsp;Get help </router-link>
                <button v-if="userIsLoggedIn" @click="signOut()" class="button is-danger">Sign out</button>
            </div>
            <div class="spacer s100"></div>
        </div>
    </div>

    <ReturnFormValuesModal
        v-if="showAddFleet"
        theme="is-primary"
        title="Add a New Fleet"
        submitText="Add Fleet"
        :sentForm="formNewFleet"
        @sendResult="getFleetResult($event)">
    </ReturnFormValuesModal>

    <ReturnFormValuesModal
        v-if="showAddVessel"
        theme="is-primary"
        title="Add a New Vessel"
        submitText="Add Vessel"
        :sentForm="formNewVessel"
        @sendResult="getVesselResult($event)">
    </ReturnFormValuesModal>

    <BackgroundsModal
        v-if="showBackgrounds"
        theme="is-primary"
        title="Select a Header Background"
        :selectedBG="dashBackground"
        submitText="Done"
        @closeModal="close()"
        @saveBackground="saveBackground($event)">
    </BackgroundsModal>

    <InfoModal v-if="showFleetPrompt" theme="is-primary" title="Consider a Fleet" submitText="Done" @closeModal="close()">
        <div class="centered">
            <div class="spacer s10"></div>
            <p>
                You may have seen the Fleet feaure on OffshoreSMS. This is a great way to manage multiple vessels in one place with the awesome Fleet
                Dashboard
            </p>
            <div class="spacer s10"></div>
            <p>If you haven't already, perhaps consider adding this vessel to a Fleet.</p>
            <p>If you only have one vessel, then adding it here is fine too.</p>
            <!-- <div class="spacer s20"></div>
          <img class="logo" src="@/assets/img/general/bobs-fleet.jpg" alt="Offshore SMS"> -->

            <div class="spacer s30"></div>
            <div class="buttons centered">
                <button class="button is-primary" @click="addMyOwnVessel()">Add my own vessel</button>
                <button class="button is-info" @click="addFleetVessel()">Take me to Fleets</button>
            </div>
            <!-- <div class="spacer s10"></div> -->
        </div>
    </InfoModal>

    <InfoModal v-if="showCrewPortal" theme="is-primary" :fullWidth="true" title="Logs Portal" submitText="Done" @closeModal="close()">
        <h4>Logs Portal Options</h4>
    </InfoModal>

    <InfoModal v-if="showFleetPortal" theme="is-primary" :fullWidth="true" title="Fleet Portal" submitText="Done" @closeModal="close()">
        <h4>Fleet Management Portal Options</h4>
    </InfoModal>
</template>

<script>
import HomePageTabs from '@/views/SubSections/HomePageTabs.vue';
import DashboardAllFleets from '@/components/dashboard/DashboardAllFleets.vue';
import DashboardMyVessels from '@/components/dashboard/DashboardMyVessels.vue';
import ReturnFormValuesModal from '@/components/modals/ReturnFormValuesModal.vue';
import InfoModal from '@/components/modals/InfoModal.vue';
import SimpleModal from '@/components/modals/SimpleModal.vue';
import BackgroundsModal from '@/components/modals/BackgroundsModal.vue';
import MobileNav from '@/reusables/MobileNav.vue';

import { getFirestore, collection, doc, arrayUnion, serverTimestamp, addDoc, setDoc, updateDoc } from 'firebase/firestore';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';

import { loggedInUserStore } from '@/stores/userdata';
import { currentDataStore } from '@/stores/currentdata';

import imgDefault from '@/assets/img/bgs/dashboard1.jpg';
import imgHelm from '@/assets/img/bgs/dashboard1.jpg';
import imgHarbour from '@/assets/img/bgs/harbour1.jpg';
import imgYacht from '@/assets/img/bgs/yacht1.jpg';
import imgCorals from '@/assets/img/bgs/corals.jpg';
import imgPalms from '@/assets/img/bgs/palms.jpg';
import imgSunset from '@/assets/img/bgs/sunset-ship.jpg';
import imgFishing from '@/assets/img/bgs/fishing-boat.jpg';
import imgRopes from '@/assets/img/bgs/ropes.jpg';
import imgShip from '@/assets/img/bgs/harbour2.jpg';

const sleep = (milliseconds) => new Promise((resolve) => setTimeout(resolve, milliseconds));

export default {
    setup() {
        const user_store = loggedInUserStore();
        const current_data = currentDataStore();
        const db = getFirestore();
        return {
            user_store: user_store,
            current_data: current_data,
            db: db
        };
    },

    components: {
        MobileNav,
        HomePageTabs,
        DashboardAllFleets,
        ReturnFormValuesModal,
        DashboardMyVessels,
        InfoModal,
        SimpleModal,
        BackgroundsModal
    },
    mounted() {
        console.log('mounted');
        this.isLoading = true;
        this.current_data.setLoadingHome(true);
        this.current_data.setHomePageLoaded(false);
        // onAuthStateChanged(getAuth(), (user) => {
        //     if (user) {
        //         setTimeout(() => {
        //             console.log('starting to load fleets');
        //             this.user_store.loadMyFleets();
        //         }, 1000);
        //     }
        // });
        this.loadMyDashboard();
    },

    computed: {
        userData() {
            return this.user_store.getUserData;
        },
        dashBackground() {
            // return this.backgrounds[this.userData.dashBackground ?? 'default'];
            return this.userData.dashBackground ?? 'default';
        },
        userID() {
            return this.user_store.getUserID;
        },
        userIsLoggedIn() {
            return this.user_store.getUserIsLoggedIn;
        },
        hasSeenFleetPrompt() {
            return this.userData.hasSeenFleetPrompt ?? false;
        },
        crewedVesselsLoaded() {
            return this.user_store.getCrewedVesselsLoaded;
        },
        homePageLoaded() {
            return this.current_data.getHomePageLoaded;
        }
    },

    data() {
        return {
            loaded: false,
            loadingFleets: true,
            showAddFleet: false,
            showAddVessel: false,
            showFleetPrompt: false,
            showBackgrounds: false,
            tab: '2',
            currentTab: 'fleet',

            formNewFleet: [
                {
                    label: 'Fleet name:',
                    type: 'text',
                    options: [],
                    placeholder: 'Type the fleet name..',
                    returnType: 'text',
                    answer: '',
                    fieldName: 'companyName',
                    required: true
                },
                {
                    label: 'Fleet location:',
                    type: 'text',
                    options: [],
                    placeholder: 'Type main fleet location..',
                    returnType: 'text',
                    answer: '',
                    fieldName: 'companyLocation',
                    required: true
                }
            ],

            formNewVessel: [
                {
                    label: 'Vessel name:',
                    type: 'text',
                    options: [],
                    placeholder: 'Type your vessel name..',
                    returnType: 'text',
                    answer: '',
                    fieldName: 'vesselName',
                    required: true
                },
                {
                    label: 'Vessel identifier:',
                    type: 'text',
                    options: [],
                    placeholder: 'Type identification number..',
                    returnType: 'text',
                    answer: '',
                    fieldName: 'vesselUVI',
                    required: true
                },
                {
                    label: "What is this vessel's home port?",
                    type: 'text',
                    options: [],
                    placeholder: 'Type home port..',
                    returnType: 'text',
                    answer: '',
                    fieldName: 'homePort',
                    required: false
                }
            ],
            isLoading: false,
            backgrounds: {
                default: imgDefault,
                helm: imgHelm,
                harbour: imgHarbour,
                yacht: imgYacht,
                corals: imgCorals,
                palms: imgPalms,
                sunset: imgSunset,
                fishing: imgFishing,
                ropes: imgRopes,
                ship: imgShip
            },
            showCrewPortal: false,
            showFleetPortal: false,
            alerts: {
                messages: 3,
                news: 1
            }
        };
    },

    methods: {
        async loadMyDashboard() {
            if (this.homePageLoaded || this.loaded) return;
            this.loaded = true;
            this.current_data.setHomePageLoaded(true);
            await sleep(500);
            // console.log('starting to load fleets');
            //
            this.test();
        },
        async test() {
            // console.log('test');
            this.current_data.setLoadingHome(false);
            await sleep(1500);
            this.isLoading = false;
        },
        close() {
            this.showAddFleet = false;
            this.showAddVessel = false;
            this.showFleetPrompt = false;
            this.showBackgrounds = false;
            this.showFleetPortal = false;
            this.showCrewPortal = false;
        },

        authenticate(tab) {
            this.$router.push({
                name: 'Authenticate',
                params: {
                    sentTab: tab
                }
            });
        },

        signOut() {
            // console.log('signing out...');
            signOut(getAuth())
                .then(() => {
                    this.isLoggedIn = false;
                    this.user_store.setFirebaseUser({});
                    this.user_store.setUserID('');
                    this.user_store.logOutUser();
                    this.user_store.setUserIsLoggedIn(false);
                    this.$router.replace('authenticate');
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        saveBackground(result) {
            // console.log(result);
            let updateData = {
                dashBackground: result.answer
            };
            this.user_store.updateUserData(updateData);
            this.close();
        },

        toAbout() {
            this.$router.push({
                name: 'about',
                params: {
                    myName: 'steve123'
                }
            });
        },

        getAvatar(title) {
            // console.log(title);
            if (!title) return;
            let parts = title.split(' ');
            // console.log('parts',parts);
            let initials = '';
            for (let i = 0; i < 2; i++) {
                if (parts[i] != undefined && parts[i].length > 0 && parts[i] !== '') {
                    initials += parts[i][0];
                }
            }
            return initials;
        },

        async getFleetResult(result) {
            if (!result.hasResult) {
                this.close();
            } else {
                this.isLoading = true;
                this.close();

                let newCompanyData = {
                    companyName: result.returnData.companyName,
                    companyLocation: result.returnData.companyLocation,
                    primaryUserEmail: this.userData.useremail,
                    primaryUserID: this.userData.userid,
                    primaryUserName: this.userData.username,
                    primaryUserPhone: this.userData.userphone,
                    adminUsers: arrayUnion(this.userData.userid),
                    adminEmails: arrayUnion(this.userData.useremail),
                    timestamp: serverTimestamp()
                };
                const docRefA = await addDoc(collection(this.db, 'companies'), newCompanyData);

                let myNewCompany = result.returnData;
                myNewCompany.companyID = docRefA.id;

                await setDoc(doc(this.db, 'userdata/' + this.userID + '/myCompanies', docRefA.id), myNewCompany);

                setTimeout(() => {
                    this.isLoading = false;
                }, 1000);
            }
        },

        async getVesselResult(result) {
            if (!result.hasResult) {
                this.close();
            } else {
                this.isLoading = true;
                this.close();

                const trialEndDate = new Date();
                trialEndDate.setDate(trialEndDate.getDate() + 730);
                let newVesselData = {
                    vesselOwnerID: this.userID,
                    vesselName: result.returnData.vesselName.toUpperCase(),
                    vesselUVI: result.returnData.vesselUVI,
                    homePort: result.returnData.homePort,
                    vesselOwnerName: this.userData.username,
                    vesselState: '',
                    isDemo: false,
                    isPaidFor: false,
                    nextChargeDate: new Date(trialEndDate),
                    lastVesselCharge: 0,
                    nextVesselCharge: 0,
                    isCompanyVessel: false,
                    takesPassengers: true,
                    aaaPlans: ['logs'],
                    isActive: false,
                    dateAdded: new Date(),
                    isFreebie: true
                };
                const docRefA = await addDoc(collection(this.db, 'vessels'), newVesselData);

                let myNewVessel = {
                    vesselName: result.returnData.vesselName,
                    vesselUVI: result.returnData.vesselUVI
                };
                myNewVessel.vesselID = docRefA.id;

                const docRefB = doc(this.db, 'userdata', this.userID);
                await updateDoc(docRefB, {
                    myVessels: arrayUnion(myNewVessel)
                });

                setTimeout(() => {
                    this.isLoading = false;
                }, 1000);
            }
        },

        seenFleetPrompt() {
            const docRef = doc(this.db, 'userdata', this.userID);
            updateDoc(docRef, {
                hasSeenFleetPrompt: true
            });
        },

        addMyOwnVessel() {
            this.close();
            this.showAddVessel = true;
            this.seenFleetPrompt();
        },

        addFleetVessel() {
            this.close();
            this.tab = 2;
            this.seenFleetPrompt();
        }
    }
};
</script>

<style lang="scss" scoped>
.splash-header-image {
    background: url('@/assets/img/bgs/homepage1.jpg') no-repeat center center;
    background-size: cover;
    height: 500px;
    display: flex;
    align-items: center;
    max-width: 1400px;
    margin: 0 auto 3rem;
    border-radius: 10px;
    .hero-content {
        width: 90%;
        margin: 1rem 2rem;
        max-width: 700px;
        text-align: left;
        h1 {
            font-family: 'Montserrat', sans-serif;
            color: #fff;
            font-weight: bold;
        }
        p {
            color: #fff;
            font-size: 1.2rem;
            margin-bottom: 1rem;
        }
        .button {
            display: inline-block;
        }
    }
}
.header-image {
    // background: url('@/assets/img/bgs/dashboard1.jpg') no-repeat left bottom;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 210px;
    margin: 1rem 0rem 1.2rem;
    h2 {
        font-family: 'Montserrat', sans-serif;
        color: #fff;
        position: absolute;
        left: 3%;
        top: 30px;
        // font-size: 2rem;
        // font-weight: bold;
        // font-family: monospace;
        // color: cadetblue;
        text-transform: uppercase;
        // border-bottom: 1px solid #eee;
    }
    .profile-avatar {
        position: absolute;
        left: 30px;
        bottom: -80px;
        span {
            background: deepskyblue;
            &.avatar {
                width: 160px;
                height: 160px;
                display: block;
                background-size: cover;
                background-position: center;
                border-radius: 50%;
                border: 4px solid #fff;
                &.round-button-letters {
                    background: steelblue;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 3rem;
                    font-weight: bold;
                    color: #fff;
                }
            }
        }
    }
    .edit-button {
        position: absolute;
        bottom: 20px;
        right: 20px;
        width: 35px;
        height: 35px;
        background: rgba(255, 255, 255, 0.45);
        border-radius: 50%;
        padding: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        // opacity: 0;
        transition: 0.25s;
        cursor: pointer;
        &:hover {
            scale: 1.2;
            color: mediumturquoise;
        }
    }
    .home-title {
        position: absolute;
        color: #fff;
        top: 25px;
        left: 25px;
        font-size: 1.8rem;
        font-family: 'Montserrat', sans-serif;
        font-weight: bold;
    }
    &:hover {
        .edit-button {
            opacity: 1;
        }
    }
}

.buttons {
    &.header-buttons {
        justify-content: flex-end;
        margin-right: 1rem;
        .button {
            margin-left: 4px;
            span {
                &.counter {
                    position: absolute;
                    font-size: 9px;
                    top: -5px;
                    right: -5px;
                    color: #fff;
                    background: red;
                    width: 20px;
                    height: 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%;
                }
            }
        }
    }
}
.grid-wrapper {
    &.two {
        padding: 0 1rem;
    }
}
.tabs {
    .tab-button {
        padding: 12px;
        &.selected {
            color: steelblue;
            background: #f1f1f1;
        }
    }
}

.web-version {
    display: flex;
    justify-content: start;
}

.buttons {
    &.desktop {
        display: flex;
    }
    &.mobile {
        display: none;
    }
}

@media (max-width: 959px) {
    .buttons {
        &.header-buttons {
            &.desktop {
                display: none;
            }
            &.mobile {
                display: flex;
            }
        }
    }
}
@media (max-width: 1059px) {
    // .main-wrapper {
    //     .left-side {
    //         // flex-basis: 380px;
    //     }
    //     .main-side {
    //         width: calc(100% - 400px);
    //         .toggle-wrapper {
    //             max-height: 350px;
    //             .toggle-item {
    //                 width: 235px;
    //                 height: 235px;
    //                 &.active {
    //                     width: 280px;
    //                     height: 280px;
    //                 }
    //             }
    //         }
    //     }
    // }
}
@media (max-width: 550px) {
    .home-page-wrapper {
        margin-top: 5rem;
    }
    .buttons {
        &.header-buttons {
            flex-direction: column;
            align-items: flex-end;
            .button {
                margin-right: 0;
            }
        }
    }
    .header-image {
        h2 {
        }
        .profile-avatar {
            position: absolute;
            left: 20px;
            bottom: -60px;
            span {
                &.avatar {
                    width: 110px;
                    height: 110px;
                    &.round-button-letters {
                    }
                }
            }
        }
        .home-title {
            font-size: 1.2rem;
            line-height: 1.2rem;
        }
    }
}
</style>
