<template>
    <div v-if="!stateMyFleetsLoaded || !loadFleetData" class="my-fleets centered">
        <font-awesome-icon icon="sync-alt" class="fa-spin" /> &nbsp; Loading fleets...
    </div>

    <div v-if="stateMyFleetsLoaded && loadFleetData" class="my-fleets">
        <div v-if="favouriteFleets.length > 0">
            <ListMyFleets v-for="fleet in favouriteFleets" :sentFleet="fleet" :key="fleet.id">
                <template #heading>{{ fleet.companyLocation }}</template>
            </ListMyFleets>
        </div>

        <div v-if="filteredFleets.length > 0">
            <ListMyFleets v-for="fleet in filteredFleets" :showDelete="showDeleteFleets" :sentFleet="fleet" :key="fleet.id">
                <template #heading>{{ fleet.companyLocation }}</template>
            </ListMyFleets>
        </div>

        <div v-else>
            <div class="centered">
                <div class="spacer s20"></div>
                <RoundIconButton
                    icon="ship"
                    iconSize="110px"
                    fontSize="40px"
                    title="You have no fleets yet"
                    color="#9a9a9a"
                    :preventClick="true"
                    borderSize="4px"
                    v-on:action="addFleet()" />
            </div>
        </div>

        <div v-if="!stateMyClientFleetsLoaded" class="">Loading my client fleets...</div>
        <div v-else>
            <div v-if="clientFleets.length > 0">
                <ListMyFleets v-for="fleet in clientFleets" :isSupplierFleet="true" :sentFleet="fleet" :key="fleet.id">
                    <template #heading>{{ fleet.fleetLocation }}</template>
                </ListMyFleets>
            </div>
        </div>

        <!-- <div class="buttons centered">
            <button
                v-if="isNumzaan"
                class="button is-large"
                :class="[showDeleteFleets ? 'is-danger' : 'is-light']"
                @click="showDeleteFleets = !showDeleteFleets">
                <font-awesome-icon icon="trash-alt" />
            </button>
        </div> -->

        <InfoModal v-if="showFleetInfo" theme="is-primary" title="What is a Fleet?" submitText="Done" @closeModal="close()">
            <div class="centered">
                <p>
                    A fleet is simply a name for the Dashboard where all of your vessels will live. Once you add a fleet, you can add any number of
                    vessels within that fleet.
                </p>
                <p>The name of the fleet can be anything, for example your business name.</p>
                <img class="logo" src="@/assets/img/general/bobs-fleet.jpg" alt="Offshore SMS" />
            </div>
        </InfoModal>
    </div>
</template>

<script>
import ListMyFleets from '@/components/dashboard/ListMyFleets.vue';
import InfoModal from '@/components/modals/InfoModal.vue';
import RoundIconButton from '@/components/buttons/RoundIconButton.vue';

import { getFirestore, collection, query, where, onSnapshot } from 'firebase/firestore';
import { loggedInUserStore } from '@/stores/userdata';

export default {
    setup() {
        const user_store = loggedInUserStore();
        // const db = getFirestore()
        return {
            user_store: user_store
            // db: db
        };
    },

    mounted() {
        setTimeout(() => {
            this.loadFleetData = true;
            this.user_store.loadMyFleets();
        }, 1000);
    },

    props: {
        showDeleteFleets: Boolean
    },

    components: {
        ListMyFleets,
        InfoModal,
        RoundIconButton
    },

    computed: {
        userData() {
            return this.user_store.getUserData;
        },
        userID() {
            return this.user_store.getUserID;
        },
        userIsLoggedIn() {
            return this.user_store.getUserIsLoggedIn;
        },
        stateMyFleets() {
            return this.user_store.getMyFleets;
            // return [];
        },
        clientFleets() {
            return this.user_store.getMyClientFleets;
        },
        stateMyFleetsLoaded() {
            return this.user_store.getMyFleetsLoaded;
        },
        stateMyClientFleetsLoaded() {
            return this.user_store.getMyClientFleetsLoaded;
        },
        myFaveFleetIDs() {
            return this.user_store.getMyFaveFleetIDs;
        },
        favouriteFleets() {
            // return this.stateMyFleets.filter((m) => this.fleetsImAdmin.includes(m.id) && this.myFaveFleetIDs.includes(m.id));
            return this.stateMyFleets.filter((m) => this.myFaveFleetIDs.includes(m.companyID));
        },
        filteredFleets() {
            // return this.stateMyFleets.filter((m) => this.fleetsImAdmin.includes(m.id) && !this.myFaveFleetIDs.includes(m.id));
            return this.stateMyFleets.filter((m) => !this.myFaveFleetIDs.includes(m.companyID));
        },
        isNumzaan() {
            return this.user_store.getIsNumzaan;
        }
    },
    emits: ['action'],

    methods: {
        close() {
            this.showFleetInfo = false;
        },
        addFleet() {
            let result = {};
            this.$emit('action', result);
        }
    },
    data() {
        return {
            showFleetInfo: false,
            loadingFleets: true,
            loadFleetData: false
        };
    }
};
</script>
