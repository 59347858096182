import { defineStore } from 'pinia';
import { getFirestore, doc, getDoc, collection, query, orderBy, onSnapshot, getDocs, updateDoc } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';

// import type { User } from '@/types/User.types';
// import type { Vessel } from '@/types/Vessel.types';

export const loggedInUserStore = defineStore({
    // id is required so that Pinia can connect the store to the devtools
    id: 'loggedInUser',
    state: () => ({
        userIsLoggedIn: false,
        userData: {},
        userDataIsLoaded: false,
        firebaseUser: {},
        userID: '',
        myFleets: [],
        myClientFleets: [],
        myFleetsLoaded: false,
        myClientFleetsLoaded: false,
        myFaveFleetIDs: [],
        browserIP: '',
        tempUserData: {
            username: '',
            email: '',
            userid: '',
            location: '',
            browserIP: ''
        },
        tempUserPreferences: {},
        myLevelOneVessels: [],
        myLevelTwoVessels: [],
        myLevelThreeVessels: [],
        crewedVesselsLoaded: false,
        myCrewedVessels: [],
        myCrewedVesselIDs: [],
        mySavedBuilderItems: [],
        mySavedFleetBuilderItems: [],
        isNumzaan: false
    }),
    getters: {
        getFirebaseUser: (state) => state.firebaseUser,
        getUserData: (state) => state.userData,
        getUserDataIsLoaded: (state) => state.userDataIsLoaded,
        getUserID: (state) => state.userID,
        getUserIsLoggedIn: (state) => state.userIsLoggedIn,
        getMyFleets: (state) => state.myFleets,
        getMyFleetsLoaded: (state) => state.myFleetsLoaded,
        getMyClientFleetsLoaded: (state) => state.myClientFleetsLoaded,
        getMyFaveFleetIDs: (state) => state.myFaveFleetIDs,
        getMyClientFleets: (state) => state.myClientFleets,
        getBrowserIP: (state) => state.browserIP,
        getTempUserData: (state) => state.tempUserData,
        getCrewedVesselsLoaded: (state) => state.crewedVesselsLoaded,
        getMyCrewedVessels: (state) => state.myCrewedVessels,
        getTempUserPreferences: (state) => state.tempUserPreferences,
        getMySavedBuilderItems: (state) => state.mySavedBuilderItems,
        getMySavedFleetBuilderItems: (state) => state.mySavedFleetBuilderItems,
        getIsNumzaan: (state) => state.isNumzaan
    },
    actions: {
        setFirebaseUser(user) {
            this.firebaseUser = user;
        },
        async updateUserData(updateData) {
            // console.log('X:', updateData, this.userID);
            const docRef = doc(getFirestore(), 'userdata', this.userID);
            await updateDoc(docRef, updateData);
        },

        checkIfOffshoreAdmin() {
            const validateOffshoreAdmin = httpsCallable(getFunctions(), 'validateOffshoreAdmin');
            validateOffshoreAdmin({ userID: this.userID }).then((result) => {
                // console.log('numzaan:', result.data.text);
                let res = result.data.text ?? {};
                this.isNumzaan = res.valid ?? false;
            });
        },

        setUserData() {
            // console.log('loading user data');
            onSnapshot(doc(getFirestore(), 'userdata', this.userID), (doc) => {
                this.userData = doc.data() ?? {};
                // this.myLevelOneVessels = this.userData.vesselsThatICrew ?? [];
                // this.myLevelTwoVessels = this.userData.vesselsThatImLevelTwo ?? [];
                // this.myLevelThreeVessels = this.userData.vesselsThatIMaster ?? [];
                this.myFaveFleetIDs = this.userData.myFaveFleetIDs ?? [];
                this.mySavedBuilderItems = this.userData.mySavedBuilderItems ?? [];
                this.mySavedFleetBuilderItems = this.userData.mySavedFleetBuilderItems ?? [];
                this.userDataIsLoaded = true;
            });
            this.checkIfOffshoreAdmin();
        },
        async loadMyFleets() {
            // const collectionRef = collection(getFirestore(), 'userdata/' + this.userID + '/myFleets');
            // const q1 = query(collectionRef, orderBy('fleetName', 'asc'));
            // const snap = await getDocs(q1);
            // this.myFleets = [];
            // await Promise.all(
            //     snap.docs.map(async (doc) => {
            //         console.log('fleet loaded:', doc.data().fleetName);
            //         this.myFleets.push({
            //             companyID: doc.id,
            //             companyName: doc.data().fleetName,
            //             companyLocation: doc.data().fleetLocation ?? 'Location not set',
            //             companyState: doc.data().fleetLocation ?? 'Location not set',
            //             avatarUrl: doc.data().avatarUrl ?? ''
            //         });
            //     })
            // );

            const q = query(collection(getFirestore(), 'userdata/' + this.userID + '/myFleets'), orderBy('fleetName', 'asc'));
            onSnapshot(q, (querySnapshot) => {
                this.myFleets = [];
                querySnapshot.forEach((doc) => {
                    // console.log('fleet loaded:', doc.data().fleetName);
                    this.myFleets.push({
                        companyID: doc.id,
                        companyName: doc.data().fleetName,
                        companyLocation: doc.data().fleetLocation ?? 'Location not set',
                        companyState: doc.data().fleetLocation ?? 'Location not set',
                        avatarUrl: doc.data().avatarUrl ?? ''
                    });
                });
            });
            this.myFleetsLoaded = true;
            this.loadMyClientFleets();
        },
        loadMyClientFleets() {
            // console.log('loading my client fleets...');
            const q = query(collection(getFirestore(), 'userdata/' + this.userID + '/myClientFleets'), orderBy('fleetName', 'asc'));
            onSnapshot(q, (querySnapshot) => {
                this.myClientFleets = [];
                querySnapshot.forEach((doc) => {
                    this.myClientFleets.push({
                        companyID: doc.id,
                        companyName: doc.data().fleetName,
                        companyLocation: doc.data().fleetLocation ?? 'Location not set',
                        companyState: doc.data().fleetLocation ?? 'Location not set',
                        avatarUrl: doc.data().avatarUrl ?? ''
                    });
                });
            });
            this.myClientFleetsLoaded = true;
            // console.log('loading my client fleets...', this.myClientFleetsLoaded);
        },
        logOutUser() {
            this.userData = {};
            this.myFleetsLoaded = false;
            this.myClientFleetsLoaded = false;
            this.myFleets = [];
            this.userID = '';
        },
        setUserID(userid) {
            this.userID = userid;
        },
        setUserIsLoggedIn(val) {
            this.userIsLoggedIn = val;
        },
        setBrowserIP(ip) {
            this.browserIP = ip;
        },
        setTempUserData(tempUser) {
            this.tempUserData = tempUser;
        },
        async setLocalUserPreferences(fields) {
            Promise.all(
                fields.map((n) => {
                    this.tempUserPreferences[n.key] = n.value;
                })
            );
        },

        setMyCrewedVesselsLoaded(val) {
            this.crewedVesselsLoaded = val;
        },

        async setCurrentCrewedVessels() {
            if (!this.crewedVesselsLoaded) {
                // console.log('loading crewed vessels');
                setTimeout(() => {
                    this.crewedVesselsLoaded = true;
                }, 500);
                this.myCrewedVessels = [];
                this.myCrewedVesselIDs = [];
                // console.log(this.myCrewedVessels);
                //
                setTimeout(async () => {
                    let self = this;
                    let levelOneVessels = this.userData.vesselsThatICrew ?? [];
                    let levelTwoVessels = this.userData.vesselsThatImLevelTwo ?? [];
                    let levelThreeVessels = this.userData.vesselsThatIMaster ?? [];
                    // console.log(levelOneVessels, levelTwoVessels, levelThreeVessels);

                    await Promise.all(
                        levelThreeVessels.map(async (id) => {
                            if (!self.myCrewedVesselIDs.includes(id)) {
                                const docSnap = await getDoc(doc(getFirestore(), 'vessels', id));
                                if (docSnap.exists()) {
                                    self.myCrewedVesselIDs.push(id);
                                    self.myCrewedVessels.push({
                                        vesselName: docSnap.data().vesselName,
                                        vesselID: id,
                                        avatarUrl: docSnap.data().avatarUrl ?? '',
                                        role: docSnap.data().vesselRoles ? docSnap.data().vesselRoles[2] : 'Crew',
                                        alertsFlagged: docSnap.data().alertsFlagged ?? false
                                    });
                                }
                            }
                        })
                    );
                    await Promise.all(
                        levelTwoVessels.map(async (id) => {
                            if (!self.myCrewedVesselIDs.includes(id)) {
                                const docSnap = await getDoc(doc(getFirestore(), 'vessels', id));
                                if (docSnap.exists()) {
                                    self.myCrewedVesselIDs.push(id);
                                    self.myCrewedVessels.push({
                                        vesselName: docSnap.data().vesselName,
                                        vesselID: id,
                                        avatarUrl: docSnap.data().avatarUrl ?? '',
                                        role: docSnap.data().vesselRoles ? docSnap.data().vesselRoles[1] : 'Crew',
                                        alertsFlagged: docSnap.data().alertsFlagged ?? false
                                    });
                                }
                            }
                        })
                    );
                    await Promise.all(
                        levelOneVessels.map(async (id) => {
                            if (!self.myCrewedVesselIDs.includes(id)) {
                                const docSnap = await getDoc(doc(getFirestore(), 'vessels', id));
                                if (docSnap.exists()) {
                                    self.myCrewedVesselIDs.push(id);
                                    self.myCrewedVessels.push({
                                        vesselName: docSnap.data().vesselName,
                                        vesselID: id,
                                        avatarUrl: docSnap.data().avatarUrl ?? '',
                                        role: docSnap.data().vesselRoles ? docSnap.data().vesselRoles[0] : 'Crew',
                                        alertsFlagged: docSnap.data().alertsFlagged ?? false
                                    });
                                }
                            }
                        })
                    );
                }, 2000);
            }
        }
    }
});
